import notification from 'antd/lib/notification';
import 'antd/lib/notification/style/index';
// import React from 'react';

class Notification {
  success(title, text) {
    notification.success({
      message: title,
      description: text,
      duration: 3,
      style: {
        borderRadius: 11,
      }
    });
  };

  error(title, text) {
    notification.error({
      message: title,
      description: text,
      duration: 5,
      style: {
        borderRadius: 11,
      },
      key: text
    })
  };

  warning(title, text) {
    notification.warning({
      message: title,
      description: text,
      duration: 5,
      style: {
        borderRadius: 11,
      }
    });
  };

  hide() {
    notification.destroy();
  }
}


export default new Notification();
