import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './BottomSheet.css';
import { connect } from 'react-redux';
import { setShowBottomSheet } from '../../../action/loginNew';
import FormComponent from './formComponent';

const BottomSheet = ({showBottomSheet, setShowBottomSheet, setStoreCourseName,setStoreId,storeCourseName,storeId,setIsModalVisible,isRegisterModal,phoneNumber,setPhoneNumber,userRegister, setUserRegister,
    showVerifyOtpForm, setShowVerifyOtpForm
}) => {
  const variants = {
    open: { y: 0 },
    closed: { y: "100%" },
  };

  return (
    <div>
      {showBottomSheet && <div className="overlay" onClick={() => setShowBottomSheet(false)} />}
      <motion.div
        className="bottom-sheet"
        animate={showBottomSheet ? "open" : "closed"}
        initial="closed"
        variants={variants}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <div style={{marginTop:100}}>

        <FormComponent isBottomSheet={true}
         setStoreCourseName={setStoreCourseName}
         setStoreId={setStoreId}
         storeCourseName={storeCourseName}
         storeId={storeId}
         setIsModalVisible={setIsModalVisible}
         isRegisterModal={isRegisterModal}
         phoneNumber={phoneNumber}
         setPhoneNumber={setPhoneNumber}
         userRegister={userRegister}
          setUserRegister={setUserRegister}
          showVerifyOtpForm={showVerifyOtpForm} 
          setShowVerifyOtpForm={setShowVerifyOtpForm}/>
          </div>
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state) => {
    const { showBottomSheet } = state.loginNew;
    return {
        showBottomSheet
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomSheet);
