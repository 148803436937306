import React from 'react';
import "./loginPage.css"
import { CloseOutlined } from '@ant-design/icons';
import NewCarouselComponent from './newCarouselComponent';
import FormComponent from './formComponent';

function LoginCardComponent({ isLoginModal, handleCancel ,showVerifyOtpForm, setShowVerifyOtpForm,phoneNumber, setPhoneNumber,userRegister,setUserRegister,
    storeCourseName,setIsModalVisible,storeId,isMobile}) {
    

    return (
        <div className={`login-card-parent ${isLoginModal ? 'modal-width' : 'default-width'}`}>
            {isLoginModal &&
                <div className='closable-icon-container'>
                    <CloseOutlined style={{ color: "#636363", fontSize:18 }} onClick={handleCancel} />
                </div>}
             <NewCarouselComponent /> 
            <FormComponent isLoginModal={isLoginModal}
            showVerifyOtpForm={showVerifyOtpForm} 
            setShowVerifyOtpForm={setShowVerifyOtpForm}
            phoneNumber={phoneNumber} 
            setPhoneNumber ={setPhoneNumber}
            userRegister={userRegister} 
            setUserRegister={setUserRegister}
            storeCourseName={storeCourseName}
            setIsModalVisible={setIsModalVisible}
            storeId={storeId}/> 
        </div>
    )
}

export default LoginCardComponent