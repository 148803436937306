import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { enrollCourseStudent, getStudentExistingData, setLoginDetails, setShowRegisterForm, setStoreCourseName, setStoreId, setUserDetails, storePhoneNumber } from '../../../action/loginNew';
import confrimationImg from '../../../assets/NewAshreyaHome/confirmationImg.svg'
import { Button } from '../../../components/Button';


function CourseConfirmationModal({ courseConfirmationModal, setCourseComfirmationModal, loginDetails, storeCourseName, storeId, enrollCourseStudent,handleNumberSubmit ,setStoreCourseName,
    setLoginDetails,getStudentExistingData,storeUserName}) {

   


    console.log("response",storeUserName?.userName);


    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={courseConfirmationModal}
            onCancel={() => { setCourseComfirmationModal(false) }}
            onOk={() => { setCourseComfirmationModal(false) }}
            footer={null}
            closable={false}>
            <div style={{ textAlign: "center" }}>
                <img src={confrimationImg} />
            </div>
            <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Confirm</div>

            <div
                style={{
                    marginTop: "10px",
                    textAlign: 'center'
                }}
            >Hi <span style={{
                    fontSize: "16px",
                    color: "#636363",
                    fontWeight: "bold",
                }}>{storeUserName?.userName ? storeUserName?.userName : 'User' }, </span> are you sure you want to enroll to the course
                <span style={{
                    fontSize: "16px",
                    color: "#636363",
                    fontWeight: "bold",
                }}> {storeCourseName}</span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                        marginBottom:'30px'
                    }}
                >
                    <div>
                        <Button
                            onClick={(e) => { e.stopPropagation(), setCourseComfirmationModal(false),
                                setLoginDetails('userData',null)
                             }}
                        >
                            NO
                        </Button>
                    </div>
                    <div>
                        <Button
                            type='primary'
                            onClick={() => {
                                handleNumberSubmit()
                                setCourseComfirmationModal(false)
                            }}
                        >
                            YES
                        </Button>
                    </div>
                </div>


            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { showRegisterForm, loginDetails ,storeUserName} = state.loginNew;
    return {
        showRegisterForm, loginDetails,storeUserName
    }
}

const mapDispatchToProps = (dispatch) => ({
    setStoreId: (val) => dispatch(setStoreId(val)),
    setStoreCourseName: (val) => dispatch(setStoreCourseName(val)),

    setLoginDetails: (key, val) => dispatch(setLoginDetails(key, val)),
    getStudentExistingData:(callBack,successCallBack) => dispatch(getStudentExistingData(callBack,successCallBack)),
    setUserDetails:(val)=>dispatch(setUserDetails(val))

})

export default connect(mapStateToProps, mapDispatchToProps)(CourseConfirmationModal);