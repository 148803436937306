import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react'
import './styles.css'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Font13, Font17, Font19, Font23 } from '../Typography';

function CustomFooter() {
    const history = useHistory()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menu = (
        <img alt="googlestoreQr" loading="lazy"
            src={require("../../assets/Footer/googlestoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
    );

    const menu1 = (
        <img alt="appstoreQr" loading="lazy"
            src={require("../../assets/Footer/appstoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
    )

    return (
        <div style={{ zIndex: 3000 }} className='position-relative'>
            <div className='web-footer-main' style={{ width: "100%", backgroundColor: "#D6C5E9", height: "fit-content" }}>
                <div className='r-sb r-c-c web-footer-parent'>
                    <div className='download-our-app-div' >
                        <div className='download-div'><Font23 bold color="#191919" >Download Our App</Font23></div>
                        <div className='qr-code-parent'>
                            <Dropdown
                                overlay={menu}>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="qr-width"
                                        src={require("../../assets/Footer/googlePlay.svg").default}
                                        alt="googlePlay"
                                        loading="lazy"
                                    />
                                </a>
                            </Dropdown>
                            <Dropdown
                                overlay={menu1}>
                                <a
                                    href="https://apps.apple.com/in/app/ashreya/id6714475170"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="qr-width"
                                        src={require("../../assets/Footer/appStore.svg").default}
                                        alt="appStore"
                                        loading="lazy"
                                    />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='register-parent'>
                        <div className="m-r-20 register-div">
                            <Font19 color="#191919">Register / Login into web portal</Font19>
                        </div>
                        <Font17 color='#1089FF' onClick={()=>{
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                  event: "ashreya_login_register_clicked"})
                        }}>
                            <a
                                href="https://ashreya.aulas.in"
                                target="_blank"
                                rel="Vectors Academy Web Portal"
                            >ashreya Web Portal</a>
                        </Font17>
                    </div>
                </div>
                <div className='web-footer-parent'><div style={{ borderBottom: "1px solid #ffffff", width: "100%" }}></div></div>
                <div className='r-sb m-t-20 web-footer-parent' style={{ width: "100%" }}>
                    <div className='section-parent'>
                        <Link to="/"><Font23 bold className='cursor-pointer' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) 
                     window.dataLayer = window.dataLayer || [];
                     window.dataLayer.push({
                         event: "ashreya_home_clicked"})
                         }}>Home</Font23></Link>
                        <Link to="/"><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: isMobile ? 410 : 400, left: 0, behavior: "smooth" }) 
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_courses_clicked"})}}>Courses</Font17></Link>
                        <Link to="/"><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: 1500, left: 0, behavior: "smooth" }) 
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_mentorship_clicked"})}}>Mentorship</Font17></Link>
                        <Link to='/'><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: isMobile ? 2300 : 2100, left: 0, behavior: "smooth" }) 
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_results_clicked"})}}>Results</Font17></Link>
                        <Link to="/"><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: isMobile ? 2750 : 2550, left: 0, behavior: "smooth" })
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_faqs_clicked"}) }}>FAQs</Font17></Link>
                        <Link to="/"><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: isMobile ? 3900 : 4100, left: 0, behavior: "smooth" }) 
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_blogs_clicked"})}}>Blogs</Font17></Link>
                        <Link to='/'><Font17 color='#191919' className='cursor-pointer' onClick={() => { window.scrollTo({ top: isMobile ? 4300 : 4700, left: 0, behavior: "smooth" }) 
                       window.dataLayer = window.dataLayer || [];
                       window.dataLayer.push({
                           event: "ashreya_testimonials_clicked"})}}>Testimonials</Font17></Link>
                    </div>
                    <div className='section-parent'>
                        <Font23 bold className='cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "switching_ashreya_to_coaching"})
                        }}>Coaching</Font23>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_courses_clicked"})
                        }}>Courses </Font17>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_mentors_clicked"})
                        }}>Mentors</Font17>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_benefits_clicked"})
                        }}>Benefits</Font17>
                    </div>
                    <div className='section-parent'>
                        <Font23 bold className='cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/')
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "switching_ashreya_to_tutorials"})
                         }}>Tutorials</Font23>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_courses_clicked"})
                        }}>Courses</Font17>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_subjects_clicked"})
                        }}>Subjects</Font17>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_mentors_clicked"})
                        }}>Mentors</Font17>
                        <Font17 color='#191919' className='cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') 
                               window.dataLayer = window.dataLayer || [];
                               window.dataLayer.push({
                                   event: "ashreya_benefits_clicked"})
                        }}>Benefits</Font17>
                    </div>
                </div>
                <div className='web-footer-parent address-parent'>
                    <img src={require("../../assets/ashreya-primary-logo.svg").default} style={{ width: 130, }} alt="primaryLogo" loading="lazy" />
                    <Font17 color='#191919' style={{ lineHeight: 1.5 }}>2nd & 3rd Floor, 67 B, Fatick Chandra Rd, Latasil, Uzan Bazar, Guwahati, Assam 781001</Font17>
                </div>
                <div className='web-footer-child m-t-20 p-t-20 p-b-20 r-sb web-footer-parent' style={{ height: "fit-content", width: "100%", backgroundColor: "#623695" }}>
                    <Font13 color="#FFFFFF">Copyright @ Vectors Education LLP. All rights reserved.</Font13>
                    <div className='color-white r-sb' style={{ width: "25%" }}>
                        <Font13 color="#FFFFFF" className='cursor-pointer' onClick={() => { history.push("/terms-conditions") }}>{isMobile ? "T & C" : "Terms & Conditions"}</Font13>
                        <Font13 color="#FFFFFF" className='cursor-pointer' onClick={() => { history.push("/privacy-policy") }}>Privacy Policy</Font13>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomFooter