import React, { useEffect, useState } from 'react'
import './topBarStyles.css'
import "../../pages/NewAshreyaHome/components/styles.css"
import { Font13, Font15 } from '../Typography'
import { connect } from 'react-redux';
import { setSideBar } from '../../action/ashreya';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const subDomainHeaderArray = [
    {
        title: "HOME",
        subTitle: ""
    },
    {
        title: "COACHING",
        subTitle: "NEET & JEE"
    },
    {
        title: "TUTORIALS",
        subTitle: <>X & XII boards</>
    }
]

function TopBarNew({ setSideBar }) {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const history = useHistory()

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <div className={`new-top-bar ${visible ? 'visible' : 'hidden'}`} style={{ animation: visible ? "header 1.3s ease" : "" }}>
            <div className='r-sb r-c-c new-header-parent responsive-padding' >
                <div className='r-ac new-header-child1'>
                    <img className='ashreya-white-logo-size cursor-pointer' src={require("../../assets/ashreya-white-logo.svg").default} alt="ashreyaLogo" loading="lazy" onClick={() => { history.push("/") }} />
                </div>
                <div className='flex-1 r-sb-c new-header-child2' >
                    {subDomainHeaderArray?.map((item, index) => (
                        <div key={index} className='header-subDomain' style={{ backgroundColor: index == 0 ? '#CDC6E0' : '', cursor: index == 0 ? "default" : "pointer" ,}}
                            onClick={() => {
                                if (index == 1) {
                                    window.location.assign('https://coaching.ashreya.com/')
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        event: "switching_home_to_coaching"})
                                } else if (index == 2) {
                                    window.location.assign('https://tutorials.ashreya.com/')
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        event: "switching_home_to_tutorials"})
                                }
                            }}>
                            <Font15 color={index == 0 ? '#594099' : "#FFFFFF"} fontWeight={700} margin={index == 0 ? "5px 0px 0px 0px" : ""}>{item?.title}</Font15>
                            <Font13 color={index == 0 ? '#594099' : "#FFFFFF"} fontWeight={400} margin={"5px 0px 0px 0px"}>{item?.subTitle}</Font13>
                        </div>
                    ))}
                    {/* <button className='login-register-btn-style r-c-c' onClick={() => { window.open("https://ashreya.aulas.in/login") 
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: "ashreya_login_register_clicked"})
                    }}><Font15 bold color='#000000'>LOGIN/REGISTER</Font15></button> */}
                </div>
            </div>
            <div className='r-sb r-c-c new-mobile-header-parent'>
                <img className='cursor-pointer' src={require("../../assets/TopBar/hamburger.svg").default} alt="hamburger" loading="lazy" style={{ width: 25, width: 25 }} onClick={() => { setSideBar(true) }} />
                <img className='mobile-header-logo-size cursor-pointer' src={require("../../assets/ashreya-white-logo.svg").default} alt="ashreyaLogo" loading="lazy" onClick={() => { history.push("/") }} />
                <div  style={{ width: 25, width: 25 }}></div>
                {/* <img className='cursor-pointer' src={require("../../assets/TopBar/user-profile-icon.svg").default} alt="user-profile-icon" loading="lazy" onClick={() => { window.open("https://ashreya.aulas.in/login") }} style={{ width: 25, width: 25 }} /> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { subDomainHovered } = state.ashreya
    return {
        subDomainHovered
    }
};
const mapDispatchToProps = (dispatch) => ({
    setSideBar: (val) => dispatch(setSideBar(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopBarNew);