import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Banner from './components/banner';
import ExploreCourses from './components/exploreCourses';
import AshreyaApp from './components/ashreyaApp';
import MoreAboutAshreya from './components/moreAboutAshreya';
import Mentorship from './components/mentorship';
import ShiningStars from './components/shiningStars';
import FAQ from './components/faq';
import Blogs from './components/blogs';
import FloatingBtn from './components/floatingBtn';
import Testimonials from './components/testimonials';
import '../NewAshreyaHome/components/styles.css';
import { loginRegister } from '../../action/ashreya';
import { connect } from 'react-redux';
import BottomSheet from './components/bottomSheet';
import { setStoreCourseName, setStoreId } from '../../action/loginNew';

function NewAshreyaHome({setStoreCourseName, setStoreId,storeCourseName,storeId,showBottomSheet}) {
    const [showFloatingBtn, setShowFloatingBtn] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [showVerifyOtpForm, setShowVerifyOtpForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userRegister, setUserRegister] = useState({
        role: "",
        name: "",
    });
    const [showDownloadAppModal, setShowDownloadAppModal] =useState(false)
    const [isRegisterModal,setIsModalVisible]= useState(false)


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            const shouldShowBtn = window.scrollY < (isMobile ? 4300 : 4650);
            setShowFloatingBtn(shouldShowBtn);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile]);

    // useEffect(() => {
    //     document.body.classList.add('login-page');
    //     return () => {
    //       document.body.classList.remove('login-page');
    //     };
    //   }, []);

    const sections = [
        { Component: Banner, key: 'banner' },
        { Component: ExploreCourses, key: 'exploreCourses' },
        { Component: AshreyaApp, key: 'ashreyaApp' },
        // { Component: MoreAboutAshreya, key: 'moreAboutAshreya' },
        { Component: Mentorship, key: 'mentorship' },
        { Component: ShiningStars, key: 'shiningStars' },
        { Component: FAQ, key: 'faq' },
        { Component: Blogs, key: 'blogs' },
        { Component: Testimonials, key: 'testimonials' }
    ];

    return (
        <div className="container">
            <div className="content">
                {sections.map(({ Component, key }) => (
                    <AnimatedSection key={key}>
                        <Component />
                    </AnimatedSection>
                ))}
            </div>
            <BottomSheet
            setStoreCourseName={setStoreCourseName}
             setStoreId={setStoreId}
             storeCourseName={storeCourseName}
             storeId={storeId}
             setIsModalVisible={setIsModalVisible}
             isRegisterModal={isRegisterModal}
             phoneNumber={phoneNumber}
             setPhoneNumber={setPhoneNumber}
             userRegister={userRegister}
              setUserRegister={setUserRegister}
              showVerifyOtpForm={showVerifyOtpForm} 
              setShowVerifyOtpForm={setShowVerifyOtpForm}

            />
            {showBottomSheet ? <></> : <>{showFloatingBtn && <FloatingBtn />}</>}
            
        </div>
    );
}

function AnimatedSection({ children }) {
    const ref = useRef(null);
    const controls = useAnimation();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && mounted.current) {
                    controls.start('visible');
                } else if (mounted.current) {
                    controls.start('hidden');
                }
            },
            { threshold: 0.1 }
        );

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            mounted.current = false;
        };
    }, [controls]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
            }}
        >
            {children}
        </motion.div>
    );
}
const mapStateToProps = (state) => {
    const { showRegisterForm ,storeCourseName,storeId,showBottomSheet} = state.loginNew;
    return {
        showRegisterForm,storeCourseName,storeId,showBottomSheet
    }
}

const mapDispatchToProps = (dispatch) => ({
    setStoreId: (val) => dispatch(setStoreId(val)),
    setStoreCourseName: (val) => dispatch(setStoreCourseName(val))


})

export default connect(mapStateToProps, mapDispatchToProps)(NewAshreyaHome);
