import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { Font39, Font19, Font23, Font15 } from '../../../components/Typography';
import './styles.css';
import Carousel from 'react-multi-carousel';
import webBannerBg from '../../../assets/NewAshreyaHome/webBanner-bg-image.svg';
import webBanner1 from '../../../assets/NewAshreyaHome/webBanner1.svg';
import webBanner2 from '../../../assets/NewAshreyaHome/webBanner2.svg';
import webBanner3 from '../../../assets/NewAshreyaHome/webBanner3.svg';
import webBanner4 from '../../../assets/NewAshreyaHome/webBanner4.svg';
import webBanner5 from '../../../assets/NewAshreyaHome/webBanner5.svg';
import webBanner6 from '../../../assets/NewAshreyaHome/webBanner6.svg';
import mobBannerBg from "../../../assets/NewAshreyaHome/mobBanner-bg-image.svg"
import mobileBanner1 from '../../../assets/NewAshreyaHome/mobileBanner1.svg';
import mobileBanner2 from '../../../assets/NewAshreyaHome/mobileBanner2.svg';
import mobileBanner3 from '../../../assets/NewAshreyaHome/mobileBanner3.svg';
import mobileBanner4 from '../../../assets/NewAshreyaHome/mobileBanner4.svg';
import mobileBanner5 from '../../../assets/NewAshreyaHome/mobileBanner5.svg';
import mobileBanner6 from '../../../assets/NewAshreyaHome/mobileBanner6.svg';
import webBannerpyq1 from '../../../assets/NewAshreyaHome/web_banner_pyq_1.svg'
import webBannerpyq2 from '../../../assets/NewAshreyaHome/web_banner_pyq_2.svg'
import webBannerpyq3 from '../../../assets/NewAshreyaHome/web_banner_pyq_3.svg'
import mobileBannerpyq1 from '../../../assets/NewAshreyaHome/mobile_banner_pyq1.svg'
import mobileBannerpyq2 from '../../../assets/NewAshreyaHome/mobile_banner_pyq1.svg'
import mobileBannerpyq3 from '../../../assets/NewAshreyaHome/mobile_banner_pyq1.svg'


import RegisterModal from './loginModal';
import DownloadAppModal from './downloadAppModal';
import LoginModal from './loginModal';
import { connect } from 'react-redux';
import { setAppDownloadModal, setShowBottomSheet, setStoreCourseName, setStoreId } from '../../../action/loginNew';

const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

const BannerButtonGroup = ({ next, previous, goToSlide, hidden, ...rest }) => {
    const {
        carouselState: { currentSlide, deviceType }
    } = rest;
    const carouselIndicators = [2, 3, 4, 5,];
    return (
        <div className={`r-c-c banner-carousel-btn-group`}>
            {carouselIndicators.map((indicator) => (
                <div
                    key={indicator}
                    className="carousel-indicator m-r-10 cursor-pointer"
                    onClick={() => {
                        goToSlide(indicator);
                    }}
                    style={{
                        background: currentSlide === indicator ? "#594099" : "#D9D9D9",
                        width: "8px", height: "8px"
                    }}
                ></div>
            ))}
        </div>
    );
};

function Banner({setShowBottomSheet,setStoreCourseName, setStoreId,storeCourseName,storeId,setAppDownloadModal,modalVisibleApp}) {
    const bannerRef = useRef(null);
    const isInView = useInView(bannerRef, { once: false });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [isRegisterModal,setIsModalVisible]= useState(false)
    const [showVerifyOtpForm, setShowVerifyOtpForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userRegister, setUserRegister] = useState({
        role: "",
        name: "",
    });


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const carouselProps = {
        dotListClass: "carousel-dots",
        responsive: responsive,
        infinite: true,
        customTransition: "transform 500ms ease-in-out",
        transitionDuration: 500,
        arrows: false,
        showDots: false,
        autoPlaySpeed: 4000,
        pauseOnHover: false,
        renderButtonGroupOutside: true,
        customButtonGroup: <BannerButtonGroup />,
    };

    const banners = [
        { image: webBanner1, mobileImage: mobileBanner1 },
        {image:webBannerpyq1,mobileImage:mobileBannerpyq1,buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in?id=pyq" ,courseType:'free',id:3,courseName:'PYQ Solution - XII CBSE Board'},
        {image:webBannerpyq2,mobileImage:mobileBannerpyq2,buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in?id=pyq" ,courseType:'free',id:1,courseName:'PYQ Solution - XII AHSEC Board [Assamese Meduim]'},
        {image:webBannerpyq3,mobileImage:mobileBannerpyq3,buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in?id=pyq" ,courseType:'free',id:2,courseName:'PYQ Solution - XII AHSEC Board'}

        // { image: webBanner1, mobileImage: mobileBanner1 },
        // { image: webBanner2, mobileImage: mobileBanner2, buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in/all-popular-courses?id=4" ,courseType:''},
        // { image: webBanner3, mobileImage: mobileBanner3, buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in/all-popular-courses?id=6" ,courseType:''},
        // { image: webBanner4, mobileImage: mobileBanner4, buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in/all-popular-courses?id=4" ,courseType:''},
        // { image: webBanner5, mobileImage: mobileBanner5, buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in?id=pyq" ,courseType:''},
        // { image: webBanner6, mobileImage: mobileBanner6, buttonText: "ENROLL NOW", buttonLink: "https://ashreya.aulas.in?id=pyq" ,courseType:'free'},
    ];

    const BannerCard = () => (
        <div className="features-container">
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon1.svg").default} alt="Experienced IITian mentors" />
                <div className="feature-text">
                    <Font15 color='#1C67CD'>Experienced</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>IITian mentors</Font15>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon2.svg").default} alt="Digital library" />
                <div className="feature-text">
                    <Font15 color='#499F09'>Digital</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>library</Font15>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon3.svg").default} alt="24/7 support" />
                <div className="feature-text">
                    <Font15 color='#CF0058'>24/7</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>support</Font15>
                </div>
            </div>
        </div>
    );

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="banner-wrapper">
            <motion.div
                ref={bannerRef}
                className="banner-container"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={isInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                onMouseMove={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <motion.img
                    src={isMobile ? mobBannerBg : webBannerBg}
                    loading="lazy"
                    alt="banner background"
                    className="banner-background"
                />
                <Carousel {...carouselProps}
                    autoPlay={!isHovered}>
                    {banners.map((banner, index) => (
                        <motion.div
                            className="banner-container"
                            style={{ marginTop: 0 }}
                        >
                            <motion.div
                                className="banner-content"
                                initial={{ opacity: 0 }}
                                animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                            >
                                <motion.img
                                    src={isMobile ? banner.mobileImage : banner.image}
                                    loading="lazy"
                                    alt="banner content"
                                    className="banner-image"
                                />
                                {banner.buttonText ? (
                                    <motion.button
                                        className="enroll-now-btn"
                                        onClick={() => {
                                            window.dataLayer = window.dataLayer || [];
                                            window.dataLayer.push({
                                                event: "ashreya_banner_card_clicked"})
                                                if(banner?.courseType == 'free'){
                                                    setStoreId(banner?.id)
                                                    console.log("banner.id",banner.id);
                                                    setStoreCourseName(banner?.courseName)
                                                    setPhoneNumber('')
                                                    setUserRegister({
                                                        role: "",
                                                        name: "",
                                                    });
                                                    setShowVerifyOtpForm(false)
                                                    if(isMobile){
                                                        setShowBottomSheet(true)

                                                    }else{
                                                        setIsModalVisible(true)
                                                    }
                                                }

                                        }}
                                        initial={{ opacity: 0 }}
                                        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                                        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
                                        style={{ 
                                            // bottom: !isMobile && index == 1 ? "21%" : !isMobile && index == 4 ? "22%" : !isMobile && index == 5 ? "12%" : "", 
                                            // right: !isMobile && index == 1 ? "47%" : !isMobile && index == 4 ? "51%" : !isMobile && index == 5 ? "80%" : "",
                                            right:!isMobile && index == 1 ? '80%' : !isMobile && index == 2 ? '80%' : !isMobile && index == 3 ?  '80%': 0,
                                            bottom:!isMobile && index == 1 ? '12%' : !isMobile && index == 2 ? '12%':!isMobile && index == 3 ? '12%':''
                                        }}
                                    >
                                        <div className='enroll-btn-glare'></div>
                                        <Font15 color='#000000' bold>{banner.buttonText}</Font15>
                                    </motion.button>
                                ) : (
                                    <div className="banner1-buttons">
                                        <BannerButton title="COACHING" subtitle="NEET & JEE" />
                                        <BannerButton title="TUTORIALS" subtitle={<>X & XII boards</>} />
                                    </div>
                                )}
                            </motion.div>
                        </motion.div>
                    ))}
                </Carousel>
            </motion.div>
            <motion.div
                className="banner-centered-box"
                initial={{ opacity: 0 }}
                animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.5, ease: "easeInOut" }}
            >
                <BannerCard />
            </motion.div>
            <LoginModal
            isRegisterModal={isRegisterModal}
            setIsModalVisible={setIsModalVisible}
            showVerifyOtpForm={showVerifyOtpForm} 
            setShowVerifyOtpForm={setShowVerifyOtpForm}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            userRegister={userRegister}
             setUserRegister={setUserRegister}
             storeCourseName={storeCourseName}
            // setShowDownloadAppModal={setShowDownloadAppModal}
            storeId={storeId}
            />
            <DownloadAppModal
            showDownloadAppModal={modalVisibleApp} 
            setShowDownloadAppModal={setAppDownloadModal}
            /> 
                
            {/* <DownloadAppModal
            showDownloadAppModal={showDownloadAppModal} 
            setShowDownloadAppModal={setShowDownloadAppModal}
            /> */}
        </div>
    );
}

function BannerButton({ title, subtitle }) {
    return (
        <button className="banner-button" onClick={() => {
            if (title === "COACHING") {
                window.location.assign('https://coaching.ashreya.com/')
                window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        event: "switching_home_to_coaching"})
            } else if (title === "TUTORIALS") {
                window.location.assign('https://tutorials.ashreya.com/')
                window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        event: "switching_home_to_tutorials"})
            }
        }}>
            <div className='glare'></div>
            <Font19 color='#000000' bold margin={"0px 0px 5px 0px"}>{title}</Font19>
            <Font15 color='#000000'>{subtitle}</Font15>
        </button>
    );
}
const mapStateToProps = (state) => {
    const { showRegisterForm ,storeCourseName,storeId,modalVisibleApp} = state.loginNew;
    return {
        showRegisterForm,storeCourseName,storeId,modalVisibleApp
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val)),
    setStoreId: (val) => dispatch(setStoreId(val)),
    setStoreCourseName: (val) => dispatch(setStoreCourseName(val)),

    setAppDownloadModal:(val)=>(dispatch(setAppDownloadModal(val)))

    

})

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
