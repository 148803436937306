import styled, { css } from "styled-components";
import React, { useRef } from 'react';
// import Loader from "./WormLoader";

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: ${(props) => props.width ? props.width+'px' : "fit-content"}; 
  white-space: nowrap;
  padding: 6px 32px;
  cursor: ${(props) => props.disabled === true ? "not-allowed" : "pointer"};
  opacity: ${(props) => props.disabled === true ? 0.5 : 1};
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.813rem;

  ${(props) => props.type === "primary" ?
    css`
      background: #594099;
      border: 1px solid #594099;
      color: #fff;
      min-width: 120px;
      &:hover,
      &:focus,
      &:active {
        background: ${(props) => (props.loading ? "#594099" : "#402E6E")};
        border: 1px solid #594099;
        // color: ${(props) => (props.loading ? "#FFF" : "#594099")};
      }
    ` : props.type === "secondary" ?
      css`
        background: transparent;
        border: 1px solid #594099;
        color: #594099;
        min-width: 120px;
        &:hover,
        &:focus,
        &:active {
          background: rgba(89,64,163,0.05);
          border: 1px solid #402e6e;
          color: #402e6e;
        }
    ` : props.type === "transparent" ?
        css`
        background: transparent !important;
        border: none !important;
        box-shadow: none !important; 
    ` : props.type === "link" ?
          css`
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;   
        color: #1089FF;
        padding: 0;
    ` : props.type === "delete" ?
            css`
        background: white;
        border: none !important;
        color: #FF414D;
        font-style: roboto;
        &:hover,
        &:focus,
        &:active {
          background: transparent;
          color: #BF313A;
        }
    ` :
            props.type === "redSolidDelete" ?
              css`
            background: #FF414D;
            border: 1px solid #FF414D;
            color: #fff;
            min-width: 120px;
            &:hover,
            &:focus,
            &:active {
              background: ${(props) => (props.loading ? "#FF414D" : "#BF313A")};
              border: 1px solid #BF313A;
            }
    `:
              props.type === "archive" ?
                css`
        background: #636363;
        border: none !important;
        color: white;
        font-style: roboto;
    ` : props.type === "cancel" ?

        css`
            background: transparent;
            border: 1px solid #737373;
            color: #737373;
            min-width: 120px;
            &:hover,
            &:focus,
            &:active {
              background: #636363;
              border: 1px solid #636363;
              color: #ffffff;
            }
        `
        :
        props.type === "course-delete" ?

        css`
            background: transparent;
            border: 1px solid #FF414D;
            color: #FF414D;
            min-width: 120px;
            &:hover,
            &:focus,
            &:active {
              background: #FF414D;
              border: 1px solid #FF414D;
              color: #ffffff;
            }
        `
        :
                css`
        background: transparent;
        border: 1px solid #737373;
        color: #737373;
        min-width: 120px;
        &:hover,
        &:focus,
        &:active {
          background: transparent;
          border: 1px solid #404040;
          color: #404040;
        }
    `
  }
`;

const Button = (props) => {
  const ref = useRef();
  if (props.setRef) {
    props.setRef(ref);
  }

  const loaderColor = props.type === 'link' ? '#1089FF' : props.type === 'delete' ? '#FF414D' : props.type == "primary" ? "#FFFFFF" : props?.style?.color;

  return (
    <CustomButton {...props} disabled={props.disabled || props.loading} ref={ref} className="r-c-c">
   
        {props.icon ? <span style={{ marginRight: '10px', fontSize: 14 }}>{props.icon}</span> : null}
        {props.children}
    </CustomButton>
  );
}

export { Button }