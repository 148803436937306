import React from 'react'
import "./styles.css"
import { Button } from 'antd'
import { Font17 } from '../../../components/Typography'

function FloatingBtn() {
    return (
        <button className='floating-button' onClick={() => { window.open("https://ashreya.aulas.in") 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "ashreya_get_started_clicked"})
        }}>
            <Font17 color='#000000' bold >GET STARTED</Font17>
        </button>
    )
}

export default FloatingBtn