import React, { useState } from 'react'
import "./loginPage.css"
import { connect } from 'react-redux';
import LoginSignInForm from './loginSignInForm';
import { setShowRegisterForm } from '../../../action/loginNew';
import LoginRegisterForm from './loginRegisterForm';
import CourseConfirmationModal from './courseConfirmationModal'

function FormComponent({ showRegisterForm, setShowRegisterForm, isLoginModal, isBottomSheet,showVerifyOtpForm, setShowVerifyOtpForm, 
    phoneNumber, setPhoneNumber,userRegister,setUserRegister,storeCourseName,setIsModalVisible,storeId
}) {

    console.log("phoneNumber",phoneNumber);
   
    return (
        <div className={isBottomSheet ? 'bottom-sheet-new-login-form-parent' : 'new-login-form-parent'}>
           
                    {showRegisterForm ?  <LoginRegisterForm
                    setShowVerifyOtpForm={setShowVerifyOtpForm}
                    userRegister={userRegister}
                    setUserRegister={setUserRegister} 
                    isLoginModal={isLoginModal}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    storeCourseName={storeCourseName}
                    setIsModalVisible={setIsModalVisible}
                    storeId={storeId}

                    /> : 
                     <LoginSignInForm
                    setShowVerifyOtpForm={setShowVerifyOtpForm}
                    showVerifyOtpForm={showVerifyOtpForm}
                    setShowRegisterForm={setShowRegisterForm}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    userRegister={userRegister}
                    setUserRegister={setUserRegister} 
                    isLoginModal={isLoginModal}
                    storeCourseName={storeCourseName}
                    setIsModalVisible={setIsModalVisible}
                    storeId={storeId}
            />}
                    
        </div>
    )
}

const mapStateToProps = (state) => {
    const { showRegisterForm ,isBottomSheet} = state.loginNew;
    return {
        showRegisterForm,isBottomSheet
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);