import React, { useEffect, useState } from 'react';
import "./loginPage.css"
import AnimatedPhoneInput from './animatedPhoneInput';
import { connect } from 'react-redux';
import { Font13 , Font15, Font17, Font21, Font23, Heading } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { getStudentExistingData, postGenerateOtp, postGenerateRegistrationOtp, postRegisterDetails, postVerifyOtp, setLoginDetails, storePhoneNumber } from '../../../action/loginNew';
import VerifyOtpForm from './verifyOtpForm';
import CourseConfirmationModal from './courseConfirmationModal';
// import ReCaptchV3 from './reCaptchaV3'




function LoginSignInForm({ postGenerateOtp, storePhoneNumber, showVerifyOtpForm, setShowVerifyOtpForm, userRegister, setShowRegisterForm, setPhoneNumber, phoneNumber, setUserRegister, isLoginModal,setLoginDetails,storeCourseName,
    setIsModalVisible,storeId,getStudentExistingData
}) {
    const [emailIdSignIn, setEmailIdSignIn] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [storeTypedOtp, setStoreTypedOtp] = useState('');
    const [iscourseConfirmationModal, setCourseComfirmationModal] = useState(false)



    const sitekey = '6LcAkU4pAAAAANllAlCwLHYgaGPsqk4-KF9PKnT5';
    console.log("sitekey-26",sitekey);
    // const { executeReCaptcha } = ReCaptchV3({ sitekey });

    const handlePhoneInputChange = (e) => {
        if (e != undefined) {
            setPhoneNumber(e);
            storePhoneNumber(e)
        } else {
            setPhoneNumber("");
        }
    }

    const handleNumberSubmit = async () => {

        if (!phoneNumber) {
        } else {
            setVerifyLoader(true);
            try {
               

                // const recaptchaToken = await executeReCaptcha('mobileLogin');
                postGenerateOtp(
                    null,
                    () => { setVerifyLoader(false); setStoreTypedOtp('') },
                    (res) => {

                            setShowVerifyOtpForm(res);
                        
                    })
            } catch (error) {
                console.error('Error executing ReCaptcha:', error);
                setVerifyLoader(false);
            }
        }
    };

   

    return (
        <>
                     {showVerifyOtpForm ?
                    <VerifyOtpForm
                        phoneNumber={phoneNumber}
                        storeTypedOtp={storeTypedOtp}
                        setStoreTypedOtp={setStoreTypedOtp}
                        verifyLoader={verifyLoader}
                        setVerifyLoader={setVerifyLoader}
                        setShowVerifyOtpForm={setShowVerifyOtpForm}
                        userRegister={userRegister}
                        setUserRegister={setUserRegister}
                        setPhoneNumber={setPhoneNumber}
                        setEmailIdSignIn={setEmailIdSignIn}
                        setShowRegisterForm={setShowRegisterForm} 
                        isLoginModal={isLoginModal}
                        storeCourseName={storeCourseName}
                        setIsModalVisible={setIsModalVisible}
                        storeId={storeId}/>
                        :
                <div className={isLoginModal ? 'login-modal-form-width' : 'form-width'}>
                        <div className='m-b-70'><Font21 bold color='#191919'>Enter your mobile number to get started</Font21></div>
                        <div style={{ marginBottom: 73 }}>
                            <AnimatedPhoneInput
                                label="Mobile Number"
                                handleInputChange={handlePhoneInputChange}
                                isMandatory={false}
                                inputId="phone1"
                                disabled={false}
                                value={phoneNumber || ''}
                            />
                        </div>
                        <div className='m-b-10'><Font13 color='#636363'>By continuing, you agree to the <a href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer"> Terms of the service</a> and <a href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a> </Font13></div>
                        <Button
                            loading={verifyLoader}
                            type="primary"
                            style={{ width: "100%", marginBottom: 20 }}
                            disabled={!phoneNumber}
                            onClick={()=>{setCourseComfirmationModal(true),
            getStudentExistingData(()=>{},()=>{})

                            }}
                        >
                            {"VERIFY"}
                        </Button>
                        <CourseConfirmationModal
                    courseConfirmationModal={iscourseConfirmationModal}
                    setCourseComfirmationModal={setCourseComfirmationModal}
                    storeCourseName={storeCourseName}
                    storeId={storeId}
                    handleNumberSubmit={handleNumberSubmit}
                />
                        </div>}

        </>
    )
}

const mapStateToProps = (state) => {
    const { resendOtpTimer, timer, phoneNo, isUserRegistered } = state.loginNew;
    return { resendOtpTimer, timer, phoneNo, isUserRegistered };
};

const mapDispatchToProps = (dispatch) => ({
    postGenerateOtp: (captchaToken, callBack, successCallBack) => dispatch(postGenerateOtp(captchaToken, callBack, successCallBack)),
    postVerifyOtp: (otp, history, callBack, successCallBack, invalidOtpCallBack) => dispatch(postVerifyOtp(otp, history, callBack, successCallBack, invalidOtpCallBack)),
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),
    postRegisterDetails: (userRegister, callback, successCallBack, history = null) => dispatch(postRegisterDetails(userRegister, callback, successCallBack, history)),
    postGenerateRegistrationOtp: (email, callback, successCallBack) => dispatch(postGenerateRegistrationOtp(email, callback, successCallBack)),
    setLoginDetails: (key, val) => dispatch(setLoginDetails(key, val)),
    getStudentExistingData:(callBack,successCallBack) => dispatch(getStudentExistingData(callBack,successCallBack))
    

});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignInForm);
