
import Api from '../services/Api';
import Utils from '../services/Utils';
import Notification from '../components/notification'
export const HANDLE_RESEND_OTP_TIMER = 'HANDLE_RESEND_OTP_TIMER';
export const UPDATE_ORGS_STANDARD_LIST = 'UPDATE_ORGS_STANDARD_LIST';
export const SET_SHOW_REGISTER_FORM = "SET_SHOW_REGISTER_FORM";
export const STORE_PHONE_NUMBER = "STORE_PHONE_NUMBER";
export const UPDATE_ORG_DATA = "UPDATE_ORG_DATA";
export const SET_IS_USER_REGISTERED = "SET_IS_USER_REGISTERED";
export const SET_IS_OTP_VERIFIED = "SET_IS_OTP_VERIFIED";
export const SET_SHOW_DOWNLOAD_APP_MODAL = "SET_SHOW_DOWNLOAD_APP_MODAL";
export const SET_REGISTER_SUCCESS_MODAL = "SET_REGISTER_SUCCESS_MODAL";
export const SET_SHOW_LOGIN_MODAL = "SET_SHOW_LOGIN_MODAL";
export const SET_SHOW_BOTTOM_SHEET = "SET_SHOW_BOTTOM_SHEET"
export const SET_LOGIN_DETAILS = 'SET_LOGIN_DETAILS'
export const SET_STORE_COURSE_NAME = 'SET_STORE_COURSE_NAME'
export const SET_STORE_ID = 'SET_STORE_ID'
export const SET_APP_DOWNLOAD_MODAL = 'SET_APP_DOWNLOAD_MODAL'
export const SET_USER_DETAILS='SET_USER_DETAILS'


export const postGenerateOtp = (captchaToken, callBack = null, successCallBack) =>
    (dispatch, getState) => {
        const { phoneNo } = getState().loginNew;
        let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
        Utils.stopDuration();
        return Api.post("/api/login/generateOtpV2", { baseurl: 'https://ashreya.aulas.in', orgurl: 'ashreya.aulas.in' })
            .params({ phoneNo: formattedPhoneNumber, token: undefined, hideMsg: true })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    if (response?.success) {
                        dispatch(setIsUserRegistered(response?.isUserRegistered))
                        dispatch(setShowRegisterForm(!response?.isUserRegistered))
                        if (response?.isUserRegistered == true) {
                            dispatch(handleResendOtpTimer(true, 30));
                            Utils.startDuation(30, (callback) => {
                                if (callback === 0) {
                                    dispatch(handleResendOtpTimer(false, ''));
                                } else {
                                    dispatch(handleResendOtpTimer(true, callback))
                                }
                            })
                        }
                        if (successCallBack) {
                            successCallBack(response?.isUserRegistered)
                        }
                    } else {
                        if (successCallBack) {
                            successCallBack(false)
                        }
                    }
                }
            });
    };

export const postVerifyOtp = (otp, history, callBack = null, successCallBack = null, invalidOtpCallBack) =>
    (dispatch, getState) => {
        const { phoneNo } = getState().loginNew;
        let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
        return Api.post("/api/login/verifyOtpV2", { baseurl: 'https://ashreya.aulas.in', orgurl: 'ashreya.aulas.in' })
            .params({
                phoneNo: formattedPhoneNumber,
                otp,
                isWebLogin: true,
                hideMsg: true,
                skipLogin: true
            })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && (response?.show?.type === 'success' || response?.success)) {
                    console.log("");
                    dispatch(setLoginDetails('accessToken', response?.data?.accessToken))
                    console.log("setIsModalVisible", response);
                    dispatch(setLoginDetails('userData', response))
                    Utils.stopDuration();
                    if (successCallBack) {
                        successCallBack(response)
                    }
                    Notification.success("Otp verified successfully!")
                } else if (response?.success == false) {
                    invalidOtpCallBack("error2")

                }
                else if (typeof response == 'undefined') {
                    invalidOtpCallBack("error1")
                } else if (typeof response == 'object') {
                    invalidOtpCallBack("error2")
                }
            });
    };


export const sendForgotLink = (email, history, callBack = null) => {
    return (dispatch) => {
        Api.post('/login/forgot-password').params({ email }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (response && response.show.type === 'success') {
                //   history.push('/login');
            }
        });
    }
}

export const getOrgsStandardList = () => (dispatch, getState) => {
    return Api.get('/user/allCourses')
        .send((response, error) => {
            if (typeof response !== 'undefined') {
                dispatch(updateOrgsStandardList(response?.response?.items));
            }
        })
};

export const updateOrgDetails = (orgData) => ({
    type: UPDATE_ORG_DATA,
    orgData,
});

export const getOrgDetails = () => (dispatch, getState) => {
    return Api.get('/login').params({ defaultSite: true })
        .send((response, error) => {
            if (typeof response !== 'undefined') {
                dispatch(updateOrgDetails(response));
            } else {
                console.log(error);
            }
        })
};

export const postRegisterDetails = (userRegister, callback = null, successCallBack = null, history = null) => (
    dispatch,
    getState,
) => {
    const { phoneNo } = getState().loginNew;
    const param = userRegister
    let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
    const params = {
        name: param.name,
        phone: formattedPhoneNumber,
        role: "Student",
        hideMsg: true,
        isWebLogin: true,
        disableLogin: true   // as per the backend requirement 
    };
    return Api.uploadAndPost('/user/registerV2', params, (percent, response) => {
        const token = response?.data?.accessToken;

        if (callback) {
            callback()
        }
        if (response) {
            dispatch(setLoginDetails('userData', response))
            if (successCallBack) {
                successCallBack(response);

            }
        }
    });
};

export const postGenerateRegistrationOtp = (callback = null, successCallBack) => (
    dispatch,
    getState,
) => {
    const { phoneNo } = getState().loginNew;
    console.log("phoneNo", phoneNo);
    let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
    const params = {
        phoneNo: formattedPhoneNumber,
    };
    Utils.stopDuration();
    return Api.post("/api/login/generateRegistrationOtp", { baseurl: 'https://ashreya.aulas.in', orgurl: 'ashreya.aulas.in' })
        .params(params)
        .send((response, error) => {
            if (callback) {
                callback()
            }
            if (typeof response !== 'undefined') {
                if (successCallBack) {
                    successCallBack()
                }
                dispatch(handleResendOtpTimer(true, 30));
                Utils.startDuation(30, (callback) => {
                    if (callback === 0) {
                        dispatch(handleResendOtpTimer(false, ''));
                    } else {
                        dispatch(handleResendOtpTimer(true, callback))
                    }
                })
            }
        })
};
export const updateOrgsStandardList = (orgStandards) => ({
    type: UPDATE_ORGS_STANDARD_LIST,
    orgStandards,
});

export const handleResendOtpTimer = (bool, sec) => ({
    type: HANDLE_RESEND_OTP_TIMER,
    bool,
    sec,
});

export const setShowRegisterForm = (bool) => ({
    type: SET_SHOW_REGISTER_FORM,
    bool
})

export const storePhoneNumber = (val) => ({
    type: STORE_PHONE_NUMBER,
    val
})

export const setIsUserRegistered = (val) => ({
    type: SET_IS_USER_REGISTERED,
    val
})

export const setIsOtpVerified = (val) => ({
    type: SET_IS_OTP_VERIFIED,
    val
})

export const setShowDownloadAppModal = (val) => ({
    type: SET_SHOW_DOWNLOAD_APP_MODAL,
    val
})

export const setRegisterSuccessModal = (val) => ({
    type: SET_REGISTER_SUCCESS_MODAL,
    val
})

export const setShowLoginModal = (val) => ({
    type: SET_SHOW_LOGIN_MODAL,
    val
})

export const setShowBottomSheet = (val) => ({
    type: SET_SHOW_BOTTOM_SHEET,
    val
})

export const setLoginDetails = (key, val) => ({
    type: SET_LOGIN_DETAILS,
    key,
    val,
})
export const setStoreId = (val) => ({
    type: SET_STORE_ID,
    val,
})
export const setStoreCourseName = (val) => ({
    type: SET_STORE_COURSE_NAME,
    val,
})

export const setAppDownloadModal = (val) => ({
    type: SET_APP_DOWNLOAD_MODAL,
    val,
})

export const enrollCourseStudent = (storeId, response, callBack = null, successCallBack = null) => {
    return dispatch => {
        console.log("response-265", storeId);
        const params = {
            id: storeId,
            type: 'videoResource',
            userId: response?.userId ? response?.userId : response?.data?.userId

        }
        return Api.post("/api/market/enroll-user", { baseurl: 'https://ashreya.aulas.in', orgurl: 'ashreya.aulas.in' })
            .params((params))
            .setToken(params.token)
            .send((response, error) => {
                if (error) {
                    Notification.error("Failure! You are already enrolled in this video course")

                }
                if (callBack) {
                    callBack()

                }
                if (response != undefined) {
                    Notification.success("Success! Course enrolled.")
                    dispatch(setAppDownloadModal(true))
                    if (successCallBack) {
                        successCallBack()

                    }
                }
            });
    };
};

export const setUserDetails = (val) => ({
    type: SET_USER_DETAILS,
    val,
})
export const getStudentExistingData = (callBack,successCallBack) => (dispatch, getState) => {
    const { phoneNo } = getState().loginNew;
    return Api.get("/api/login/check-user", { baseurl: 'https://ashreya.aulas.in', orgurl: 'ashreya.aulas.in' })
        .params({phone:phoneNo})
        .send((response, error) => {
            
            if (typeof response !== 'undefined') {
                dispatch(setUserDetails(response))
                if (callBack) {
                    callBack()

                }
                if (response != undefined) {
                    if (successCallBack) {   
                        successCallBack()
                    }
                }
            }
        })
};