import {
    HANDLE_RESEND_OTP_TIMER,
    UPDATE_ORGS_STANDARD_LIST,
    SET_SHOW_REGISTER_FORM,
    STORE_PHONE_NUMBER,
    UPDATE_ORG_DATA,
    SET_IS_USER_REGISTERED,
    SET_IS_OTP_VERIFIED,
    SET_SHOW_DOWNLOAD_APP_MODAL,
    SET_REGISTER_SUCCESS_MODAL,
    SET_SHOW_LOGIN_MODAL,
    SET_SHOW_BOTTOM_SHEET,
    SET_LOGIN_DETAILS,
    SET_STORE_COURSE_NAME,
    SET_STORE_ID,
    SET_APP_DOWNLOAD_MODAL,
    SET_USER_DETAILS
} from "../action/loginNew";

const initialState = {
    orgData: {
        logo: "",
        orgId: ""
    },
    phoneNo: "",
    showRegisterForm: false,
    resendOtpTimer: false,
    isUserRegistered: false,
    timer: 30,
    orgStandards: [],
    isOtpVerified: false,
    showDownloadAppModal: false,
    showRegisterSuccessModal: false,
    showLoginModal: false,
    showBottomSheet:false,
    loginDetails: {},
    storeId:null,
    storeCourseName:null,
    storeUserName:null

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_RESEND_OTP_TIMER:
            return {
                ...state,
                resendOtpTimer: action.bool,
                timer: action.sec
            };
        case UPDATE_ORGS_STANDARD_LIST:
            return {
                ...state,
                orgStandards: action.orgStandards
            };
        case SET_SHOW_REGISTER_FORM:
            return {
                ...state,
                showRegisterForm: action.bool
            }
        case STORE_PHONE_NUMBER:
            return {
                ...state,
                phoneNo: action.val
            }
        case UPDATE_ORG_DATA:
            return {
                ...state,
                orgData: { ...action.orgData }
            };
        case SET_IS_USER_REGISTERED:
            return {
                ...state,
                isUserRegistered: action.val
            }
        case SET_IS_OTP_VERIFIED:
            return {
                ...state,
                isOtpVerified: action.val
            }
        case SET_SHOW_DOWNLOAD_APP_MODAL:
            return {
                ...state,
                showDownloadAppModal: action.val
            }
        case SET_REGISTER_SUCCESS_MODAL:
            return {
                ...state,
                showRegisterSuccessModal: action.val
            }
        case SET_SHOW_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: action.val
            }
        case SET_SHOW_BOTTOM_SHEET:
            return {
                ...state,
                showBottomSheet: action.val
            }
            case SET_LOGIN_DETAILS: {
                let newParamsObj = { ...state.loginDetails }
                newParamsObj[action.key] = action.val;
    
                return {
                    ...state,
                    loginDetails: newParamsObj,
                }
            }
            case  SET_STORE_COURSE_NAME:{
                return {
                    ...state,
                    storeCourseName: action.val
                }
            }
            case SET_STORE_ID:{
                return{
                    ...state,
                    storeId: action.val

                }}
            case SET_APP_DOWNLOAD_MODAL:{
                return{
                    ...state,
                    modalVisibleApp:action.val
                }
            }
            case SET_USER_DETAILS:{
                return{
                    ...state,
                    storeUserName:action.val
                }
            }
            
        default:
            return state;
    }
}

export default reducer;