import React, { useEffect, useRef, useState } from 'react';
import { Font15, Font17, Heading } from '../../../components/Typography';
import "./styles.css";
import blog1Image from "../../../assets/Blog/blog1Image.svg";
import blog2Image from "../../../assets/Blog/blog2Image.svg";
import blog3Image from "../../../assets/Blog/blog3Image.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { motion } from 'framer-motion';

const blogsData = [
    {
        image: blog1Image,
        title: "How did it all start? A question that has lingered in my thoughts from a time I barely remember. How did life...",
    },
    {
        image: blog2Image,
        title: "Hey there, curious minds! Welcome to my corner of the universe, where atoms collide, reactions unfold, and... ",
    },
    {
        image: blog3Image,
        title: "In the vast landscape of education, where dreams are nurtured and futures are forged, few individuals ...",
    },
];

const handleBlogCardClick = (index, history) => {
    const selectedBlog = blogsData[index];
    if (selectedBlog) {
        if (index === 0) {
            history.push('/blog/the-world-through-a-microscopic-lens')
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "ashreya_blogs_the_world_clicked"})
        } else if (index === 1) {
            history.push('/blog/my-chemistry-chronical')
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "ashreya_blogs_chronical_clicked"})
        } else {
            history.push('/blog/empowering-students-for-sucess')
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "ashreya_blogs_sucess_clicked"})
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
};

const BlogsCard = ({ item, onClick, inView }) => {
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", cursor: "pointer" }} onClick={onClick}>
            <div style={{ overflow: "hidden", borderRadius: "10px", width: "100%" }}>
                <motion.img
                    src={item.image}
                    loading="lazy"
                    alt="blogs-image"
                    className="m-b-10"
                    style={{ width: "100%" }}
                    initial={{ scale: 0.8 }}
                    animate={inView ? { scale: 1 } : { scale: 0.8 }}
                    whileHover={{ scale: 1.08 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                />
            </div>
            <Font15 lineHeight={26} color={"#636363"} className="truncate-2-lines blog-descriptionText">
                {item?.title}
            </Font15>
        </div>
    );
}

function Blogs() {
    const [isMobile, setIsMobile] = useState(false);
    const [inView, setInView] = useState(false);
    const blogsRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setInView(true);
                    } else {
                        setInView(false);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (blogsRef.current) {
            observer.observe(blogsRef.current);
        }

        return () => {
            if (blogsRef.current) {
                observer.unobserve(blogsRef.current);
            }
        };
    }, []);

    const displayedBlogs = isMobile ? blogsData.slice(0, 2) : blogsData;

    return (
        <div className='blogs-container responsive-padding' ref={blogsRef}>
            <Heading bold>Blogs</Heading>
            <center className='m-t-20 blogs-sub-heading-width'>
                <Font17 lineHeight={30}>Explore our blog for insights, tips, and updates on education, innovation, and student success. Stay inspired and informed!</Font17>
            </center>
            <div className='r-sb blogsCard-parent'>
                {displayedBlogs?.map((item, index) => (
                    <BlogsCard item={item} key={index} onClick={() => handleBlogCardClick(index, history)} inView={inView} />
                ))}
            </div>
            <div className='cursor-pointer view-all-margin' style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }} onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); history.push("/blogs") 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "ashreya_blogs_clicked"})}}>
                <img src={require("../../../assets/NewAshreyaHome/right-chevron.svg").default} style={{ marginRight: 5 }} alt="chevron" />
                <Font17 color="#1089FF" bold >VIEW ALL</Font17>
            </div>
        </div>
    );
}

export default Blogs;
