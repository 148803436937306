import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import LoginCardComponent from './loginCardComponent';
import { connect } from 'react-redux';
import { setShowRegisterForm, storePhoneNumber } from '../../../action/loginNew';

function LoginModal({isRegisterModal,setIsModalVisible,setShowRegisterForm,storePhoneNumber,
    showVerifyOtpForm, setShowVerifyOtpForm,phoneNumber, setPhoneNumber,userRegister, setUserRegister,
    storeCourseName,storeId,isMobile
}) {
    
    const handleCancel = () => {
        setIsModalVisible(false)
        setShowRegisterForm(false)
        setShowVerifyOtpForm(false)
        storePhoneNumber(null)
        setPhoneNumber('')
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isRegisterModal}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <LoginCardComponent isLoginModal={true} handleCancel={handleCancel} 
            showVerifyOtpForm={showVerifyOtpForm} 
            setShowVerifyOtpForm={setShowVerifyOtpForm}
            phoneNumber={phoneNumber} 
            setPhoneNumber ={setPhoneNumber}
            userRegister={userRegister} setUserRegister={setUserRegister}
            storeCourseName={storeCourseName}
            setIsModalVisible={setIsModalVisible}
            storeId={storeId}/>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { showRegisterForm } = state.loginNew;
    return {
        showRegisterForm,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),


})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);