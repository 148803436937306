import Api from "./Api";


let  interval; 
class Utils {
  sendMessage(text, user, additionalData, date) {
    const id = Math.round(Math.random() * 1000000);
    return {
      _id: id,
      id,
      text: text || "",
      createdAt: date || new Date(),
      user: {
        _id: user._id || user.id,
        id: user._id || user.id,
        name: user.name?.split(" ")[0],
        avatar: user.image ? user.image : null
      },
      ...additionalData
    };
  }

  sendMessages(text, user, additionalData, date) {
    const id = Math.round(Math.random() * 1000000);
    return {
      _id: id,
      id,
      message: text || "",
      createdAt: date || new Date(),
      user: {
        _id: user?._id || user.id,
        id: user._id || user.id,
        name: user.name.split(" ")[0],
        avatar: user.image ? user.image : null
      },
      ...additionalData
    };
  }

  

  secondsToHms = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if(h<=9){h= '0'+h};
    if(m<=9){m= '0'+m};
    if(s<=9){s= '0'+s};
    return `${h}:${m}:${s}`;
  }
  secondsToHm = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if(h<=9){h= '0'+h}
    if(m<=9){m= '0'+m}
    if(s<=9){s= '0'+s}
    return `${h}:${m}`;
  }
  

  startDuation(durationSec, callback){
    interval = setInterval(() => {
      // console.log('ppppppp');
      if(durationSec > 0){
        durationSec = durationSec - 1
        callback(durationSec)
        if(durationSec === 0){
          // console.log('hiiii');
          clearInterval(interval);
        }
      }else{
        clearInterval(interval);
      }
    }, 1000);
  }

  stopDuration() {
    // console.log('eeeeeeee');
    clearInterval(interval);
  }
  throttle(fn, delay=1000) {
    let throttleTimeOut = false
    return (...args) => {
      if (throttleTimeOut){
        return
      }
      fn(...args)
      throttleTimeOut = true
      setTimeout(()=>{
        throttleTimeOut = false
      }, delay);
    }
  }
  clamp(val, lowerlimit, upperlimit){
    if (val<lowerlimit){
      val = lowerlimit
    }else if(val>upperlimit){
      val = upperlimit
    }
    return val
  }
  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  isValidString(inputString) {
    // Define a regular expression pattern for spaces, alphabets, and numerics
    var pattern = /^[a-zA-Z0-9\s]+$/;
  
    // Test the input string against the pattern
    return pattern.test(inputString);
  }
  onlyDigits(s) {
    for (let i = s?.length - 1; i >= 0; i--) {
      const d = s.charCodeAt(i);
      if (d < 48 || d > 57) return false
    }
    return true
  }
  throttleHigh(func, wait, option = { leading: true, trailing: true }) {
    var { leading, trailing } = option;
    var lastArgs = null;
    var timer = null;
  
    const setTimer = () => {
      if (lastArgs && trailing) {
        func.apply(this, lastArgs);
        lastArgs = null;
        timer = setTimeout(setTimer, wait);
      } else {
        timer = null;
      }
    };
  
    return function (...args) {
      if (!timer) {
        if (leading) {
          func.apply(this, args);
        }
        timer = setTimeout(setTimer, wait);
      } else {
        lastArgs = args;
      }
    }
  }
  getBroadcastUrls(key){
    switch(key){
      case 'server':
        return ((window.location.hostname === 'localhost' && Api._headers?.orgurl === 'test.aulas.in') || window.location.hostname==='test.aulas.in')?'aulasstudentdev':'aulasstudentuat'
    }
  }
  __rest(s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
  };
}

export default new Utils();
