import React, { useState } from 'react';
import "./textFieldStyles.css";


function AnimatedTextField({ label, value, handleInputChange, isMandatory, inputId, isDisabled, isActive, placeholder, style, width, inputstyle = {}, type, prefix, fromCourseCreation }) {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const handleInputFocus = () => {
        if (!value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.add('shrink');
        } else if (value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.add('abcd');
        }
    };

    const handleInputBlur = () => {
        if (!value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('shrink');
        } else if (value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('abcd');
        }
    };

    return (
        <div className="custom-input-container m-t-20" style={{ ...style, ...(prefix ? { display: 'flex', alignItems: 'baseline' } : null) }}>
            {/* <label htmlFor={`customInput_${inputId}`} id={`customInputLabel_${inputId}`} className={`custom-input-label ${value ? 'shrink' : ''} normal-font`}>
                {label}{" "} {isMandatory ? <span style={{ color: 'red' }}>*</span> : <></>}
            </label> */}
            <label
                htmlFor={`customInput_${inputId}`}
                id={`customInputLabel_${inputId}`}
                className={`custom-input-label ${value ? 'shrink' : ''} normal-font ${fromCourseCreation ? 'only-for-course-creation-label' : ''}`}
            >
                {label}{" "}
                {isMandatory ? <span style={{ color: 'red' }}>*</span> : <></>}
            </label>
            {prefix ? <span className='custom-input-prefix' style={{ fontSize: 14, color: style.color || "#636363" }}>{prefix}</span> : null}
            <input
                type={type === "password" && isPasswordVisible ? "text" : type || "text"}
                id={`customInput_${inputId}`}
                className="custom-input"
                value={value ? value : ''}
                onChange={handleInputChange}
                style={{ color: "#636363", fontSize: 14, backgroundColor: isActive ? "#F6F4FE" : "#FFFFFF", width: width ? width : "", cursor: isDisabled ? 'not-allowed' : 'text', ...inputstyle }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                disabled={isDisabled}
                placeholder={placeholder}
            />
            
          
        </div>
    )
}

export default AnimatedTextField;