import { Modal } from 'antd'
import React from 'react';


function DownloadAppModal({ showDownloadAppModal, setShowDownloadAppModal }) {
    const handleCancel = () => {
        setShowDownloadAppModal(false)
       
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={showDownloadAppModal}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            width={600}>
            <center className='m-t-20'>
                <div style={{fontSize:'1.438rem',fontWeight:700,color:'#191919'}}>Experience the learning on ashreya app</div>
                <div className='m-t-20' style={{fontSize:'1.063rem',fontWeight:700,color:'#191919'}}>Download the app from Play Store/App Store to continue!</div>
                <div className="c-cb " 
                style={{
                    width: '100%',display:"flex",flexDirection:"row",alignItems:'center',justifyContent:"space-evenly",marginTop:10
                   }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a
                           href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                 src={require("../../../assets/Footer/googlePlay.svg").default}
                                alt="googlePlay"
                                loading="lazy"
                                className="playStore-image"
                            />
                        </a>
                        
                            <img
                                loading="lazy"
                                alt="googlestoreQr"
                                className="qrcode-image"
                                src={require("../../../assets/Footer/googlestoreQr.png")} 
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a
                            href="https://apps.apple.com/in/app/ashreya/id6714475170"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={require("../../../assets/Footer/appStore.svg").default}

                                alt="appStore"
                                loading="lazy"
                                className="playStore-image"
                            />
                        </a>
                            <img
                                alt="appstoreQr"
                                loading="lazy"
                                className="qrcode-image"
                                src={require("../../../assets/Footer/appstoreQr.png")} 
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        
                    </div>
                </div>
            </center>
        </Modal>
    )
}

export default DownloadAppModal